import type { Column, ColumnUpdate, Datasource } from "@/model"
import { authorisedFetch } from "./base"

export const getDatasources = async (): Promise<{
  datasources: Datasource[]
  usages: { [datasourceId: string]: boolean }
}> => {
  return authorisedFetch("/datasources")
}

export const deleteDatasource = async (id: string): Promise<void> => {
  return authorisedFetch(`/datasources/${id}`, {
    method: "DELETE",
  })
}

export const getDatasource = async (
  id: string,
): Promise<{
  datasource: Datasource
  records: Record<string, any>[]
  columns: Column[]
  total: number
}> => {
  return authorisedFetch(`/datasources/${id}`)
}

export const getChildDatasources = async (
  parentId: string,
): Promise<
  {
    datasource: Datasource
    records: Record<string, any>[]
    columns: Column[]
    total: number
  }[]
> => {
  return authorisedFetch(`/datasources/${parentId}/children`)
}

export const updateDatasource = async (
  datasource: Datasource,
): Promise<Datasource> => {
  return authorisedFetch(`/datasources/${datasource.id}`, {
    method: "PATCH",
  })
}

export const updateDatasourceColumns = async (
  datasourceId: string,
  columns: ColumnUpdate[],
): Promise<Datasource> => {
  return authorisedFetch(`/datasources/${datasourceId}/columns`, {
    method: "PATCH",
    body: JSON.stringify({
      columns,
    }),
  })
}

export const createDatasource = async (
  formData: FormData,
  isPending?: boolean,
): Promise<Datasource> => {
  const url = isPending ? "/datasources/pending" : "/datasources"
  return authorisedFetch(url, {
    method: "POST",
    body: formData,
  })
}

export const uploadFile = async (
  file: File,
): Promise<{ message: string; filename: string }> => {
  const formData = new FormData()
  formData.append("file", file)

  return authorisedFetch("/datasources/upload", {
    method: "POST",
    body: formData,
  })
}
