import type { Series } from "@/model"
import { defaultColor } from "@/preference"
import { selectDatasources, useAppSelector } from "@/store"

export const Legend = ({ series }: { series: Series }) => {
  if (!series) return null
  const datasources = useAppSelector(selectDatasources)

  // Find the column matching series.y in the datasource
  const datasource = datasources[series.datasource_id]
  const column = datasource?.columns?.find(col => col.name === series.y)

  // Use column friendly_name if available, otherwise use column.name, or series.y as fallback
  const displayName = column?.friendly_name || column?.name || series.y

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
      <div
        style={{
          width: 12,
          height: 12,
          backgroundColor: (series?.color) || defaultColor,
          marginRight: 6
        }}
      />
      {displayName}
    </div>
  )
}
