import type { Column, Datasource, Series } from "@/model"
import { setDatasourceComponentEnabled, useAppDispatch } from "@/store"
import { useAxisInput } from "../useAxisInput"
import { ColumnTypeIcon } from "./ColumnTypeIcon"

export const AxisInput = ({
  value,
  axis,
  title,
  onChange,
  series,
  remainingSeries,
  datasources,
}: {
  value: string
  title: string
  axis: "x" | "y"
  series: Series
  remainingSeries: Series[]
  datasources: Record<string, Datasource>
  onChange: (data: { datasourceId: string; column: Column }) => void
}) => {
  const dispatch = useAppDispatch()
  const {
    isDroppable,
    isDragOver,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  } = useAxisInput({
    axis,
    series,
    remainingSeries,
    datasources,
  })

  const currentType = datasources[series.datasource_id]?.columns.find(
    col => col.name === value,
  )?.type as "number" | "datetime"

  const currentColumn = datasources[series.datasource_id]?.columns.find(
    col => col.name === value,
  )

  const enableDatasourceComponent = () => {
    dispatch(setDatasourceComponentEnabled(true))
  }

  // Get the display name (friendly_name if exists, otherwise name)
  const getColumnDisplayName = () => {
    if (!value) return "Drag & Drop Column"
    if (!currentColumn) return value
    return currentColumn.friendly_name || currentColumn.name
  }

  return (
    <div
      className={`flex flex-grow min-w-0 border rounded h ${
        !value && "border-dashed border-blue-400 text-blue-400"
      } ${isDragOver && "border-blue-400 bg-blue-100"} ${
        !isDroppable && "border-red-500 bg-red-200"
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={e => handleDrop(e, onChange)}
    >
      <div
        className={`flex-shrink-0 flex rounded-l items-center px-3 py-2 text-xs font-semibold text-gray-700 whitespace-nowrap ${
          isDragOver ? "bg-blue-100" : "bg-gray-100"
        } ${!isDroppable && "bg-red-200"}`}
      >
        {title}
      </div>
      <div
        className={`flex-grow flex items-center px-3 ${!value && "cursor-pointer"}`}
        onClick={enableDatasourceComponent}
      >
        {currentType && (
          <div className="w-3 h-3 mr-2">
            <ColumnTypeIcon type={currentType} />
          </div>
        )}
        {getColumnDisplayName()}
      </div>
    </div>
  )
}
