import {
  FUNC_RISING_TIME,
  FUNC_STEPS_FUNCTION,
  RisingTimeData,
  SeriesFunctionData,
  TASK_EDIT,
  TASK_MEASURE,
  type Task,
  type ZoomSelection,
} from "@/model"
import { defaultColor } from "@/preference"
import {
  addReferenceLine,
  addSeriesFunction,
  deleteReferenceLine,
  doZoomRequest,
  getPlotData,
  selectChart,
  selectFunctionActiveSeriesId,
  selectPlot,
  selectPlotHoverValues,
  selectPlotXType,
  selectPlotZoomRequest,
  selectPlotZoomSelection,
  selectSeriesMap,
  useAppDispatch,
  useAppSelector,
  zoomPlot,
} from "@/store"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Grid } from "@visx/grid"
import { Group } from "@visx/group"
import { utcFormat } from "d3-time-format"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { v4 as uuidv4 } from "uuid"
import { Annotations } from "./Annotations"
import { BoxSelection } from "./BoxSelection"
import { Guider } from "./Guider"
import { ReferenceLines } from "./ReferenceLines"
import { SeriesViewer } from "./SeriesViewer"
import { useBoxSelection } from "./useBoxSelection"
import { useCanvasSize } from "./useCanvasSize"
import { useGuideLineHighlight } from "./useDatapointHighlight"
import { useScales } from "./useScale"
import { createDatasetAxisFormatter } from "./util"
import { Legend } from "./Legend"

interface PlotViewerProps {
  plotId: string
  task?: Task
  hideBoxes?: boolean
  readonly?: boolean
}

const margin = { top: 20, right: 20, bottom: 60, left: 60 }

const PlotViewer: React.FC<PlotViewerProps> = ({
  plotId,
  task = { task: TASK_EDIT },
  hideBoxes,
  readonly = false,
}) => {
  const chart = useAppSelector(selectChart)
  const plot = useAppSelector(selectPlot(plotId))
  const seriesesData = getPlotData(plotId)
  const xType = useAppSelector(selectPlotXType(plotId))
  const serieses = useAppSelector(selectSeriesMap(plotId))
  const chartRef = useRef<SVGSVGElement>(null)
  const zoomRequest = useAppSelector(selectPlotZoomRequest(plotId))
  const dispatch = useAppDispatch()

  const { parentRef, width, height, innerWidth, innerHeight } =
    useCanvasSize(margin)

  // Memoize the datasets preparation to prevent unnecessary recalculations
  const memoizedDatasets = useMemo(() => {
    if (!seriesesData) return []
    return seriesesData.map(series =>
      (series.data_points || []).concat(
        (series.functions_data || []).flatMap(func => func.data_points),
      ),
    )
  }, [seriesesData]) // Only recalculate when seriesesData changes

  // Memoize the zoom object
  const memoizedZoom = useMemo(() => plot?.zoom || {}, [plot?.zoom])

  // Memoize xType with a default value
  const memoizedXType = useMemo(() => xType || "number", [xType])

  // Now use useScales with memoized values
  const { xScale, yScale } = useScales({
    datasets: memoizedDatasets,
    innerWidth,
    innerHeight,
    zoom: memoizedZoom,
    xType: memoizedXType,
  })

  const { formatAxisX, formatAxisY } = useMemo(
    () =>
      createDatasetAxisFormatter(
        {
          xType: xType || "auto",
        },
        seriesesData || [],
      ),
    [seriesesData, xType],
  )
  const formatAxisXGuider = (v: number) => {
    if (xType === "datetime") {
      const date = new Date(v / 1e6)
      return utcFormat("%d/%b/%Y %H:%M:%S.%L")(date)
    }

    return formatAxisX(v)
  }

  const isMeasureDistanceTask =
    task.task === TASK_MEASURE && task.subtask === "distance"
  const isMeasureRisingTimeTask =
    task.task === TASK_MEASURE && task.subtask === FUNC_RISING_TIME
  const isUsingFunctions =
    task.task === TASK_MEASURE && task.subtask === FUNC_STEPS_FUNCTION

  const canSelectBox =
    task.task === TASK_EDIT ||
    isMeasureDistanceTask ||
    isMeasureRisingTimeTask ||
    isUsingFunctions
  const [boxSelected, setBoxSelected] = React.useState(false)
  const activeSeriesFunctionId = useAppSelector(
    selectFunctionActiveSeriesId(plot?.id),
  )

  const onBoxSelection = useCallback(
    (selection: ZoomSelection, cancelSelection: () => void) => {
      // Make this so that the mouse up event will only take effect in the next click
      // This code is a hack. This is to prevent the measurement box in the measure distance task to disappear.
      // The box will disappear when the user clicks on anything else.
      if (isMeasureDistanceTask) {
        setTimeout(() => {
          setBoxSelected(true)
        }, 300)

        return
      }

      cancelSelection()

      if (task.task === TASK_EDIT) {
        dispatch(
          doZoomRequest({
            plotId,
            zoomRequest: {
              x_pixel_min: selection.rect.x,
              x_pixel_max: selection.rect.x + selection.rect.width,
              y_pixel_min: selection.rect.y,
              y_pixel_max: selection.rect.y + selection.rect.height,
            },
          }),
        )
      }

      if (
        task.task === TASK_MEASURE &&
        (task.subtask === FUNC_RISING_TIME ||
          task.subtask === FUNC_STEPS_FUNCTION)
      ) {
        const seriesId =
          activeSeriesFunctionId ||
          ((Object.values(serieses) || []).length
            ? Object.values(serieses)[0].id
            : undefined)
        if (!seriesId) {
          return
        }

        const data: SeriesFunctionData = {
          x_min: xScale.invert(selection.rect.x),
          x_max: xScale.invert(selection.rect.x + selection.rect.width),
          y_min: yScale.invert(selection.rect.y + selection.rect.height),
          y_max: yScale.invert(selection.rect.y),
        }

        if (task.subtask === FUNC_RISING_TIME) {
          ; (data as RisingTimeData).settling_band = 0.02
            ; (data as RisingTimeData).smooth_sigma = 50
        }

        dispatch(
          addSeriesFunction({
            chartId: chart!.id,
            plotId,
            seriesId,
            seriesFunction: {
              id: uuidv4(),
              type: task.subtask,
              data,
              style: {
                color: defaultColor,
              },
            },
            position: {
              x: selection.rect.x,
              y: selection.rect.y,
              subx: Math.min(
                selection.rect.x + selection.rect.width + 130,
                innerWidth - 200,
              ),
              suby: Math.max(selection.rect.y, 10),
            },
            refreshData: true,
          }),
        )
      }
    },
    [
      dispatch,
      plotId,
      task,
      isMeasureDistanceTask,
      chart,
      xScale,
      yScale,
      activeSeriesFunctionId,
      serieses,
      innerWidth,
    ],
  )

  const {
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    clearSelection,
  } = useBoxSelection({
    enabled: canSelectBox,
    plotId,
    xScale,
    yScale,
    innerWidth,
    innerHeight,
    margin,
    onSelection: onBoxSelection,
  })

  useEffect(() => {
    // we will retain the box when it's a measure distance task
    // and when user clicks on anything later, the box should disappear
    const handleGlobalMouseUp = () => {
      if (isMeasureDistanceTask && boxSelected) {
        clearSelection()
        setBoxSelected(false)
      }
    }

    window.addEventListener("mouseup", handleGlobalMouseUp)
    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp)
    }
  }, [clearSelection, boxSelected, isMeasureDistanceTask])

  const hoverValues = useAppSelector(selectPlotHoverValues(plotId)) || {}
  const zoomSelection = useAppSelector(selectPlotZoomSelection(plotId))

  const zoom = useMemo(() => {
    if (!zoomRequest) return

    if (zoomRequest === "zoomout") {
      return {}
    }

    const { x_pixel_min, x_pixel_max, y_pixel_min, y_pixel_max } = zoomRequest
    const zoomData = {
      x_min: xScale.invert(x_pixel_min),
      x_max: xScale.invert(x_pixel_max),
      y_min: yScale.invert(y_pixel_max), // yes, y_min = invert of y_pixel_max because the Y start from bottom up
      y_max: yScale.invert(y_pixel_min),
    }

    return zoomData
  }, [zoomRequest]) // don't add xScale, yScale as it will cause infinite loop

  const dispatchZoom = useCallback(() => {
    if (zoom) {
      dispatch(zoomPlot({ plotId, zoom, readonly }))
    }
  }, [zoom, dispatch, plotId, readonly])

  React.useEffect(() => {
    dispatchZoom()
  }, [dispatchZoom])

  const handleHorizontalPlusClick = () => {
    if (hoverValues.yScale !== undefined) {
      onReferenceLineAdded?.(hoverValues.yScale, "horizontal")
    }
  }

  const handleVerticalPlusClick = () => {
    if (hoverValues.xScale !== undefined) {
      onReferenceLineAdded?.(hoverValues.xScale, "vertical")
    }
  }

  const handleReferenceLineDelete = (id: string) => () => {
    onReferenceLineDelete?.(id)
  }

  const highlightPoints = useGuideLineHighlight({
    seriesData: seriesesData || [],
    xScale,
    yScale,
    guideLineX: hoverValues.xPixel,
    guideLineY: hoverValues.yPixel,
    threshold: 3, // Adjust this value to change the highlight sensitivity
  })

  const onReferenceLineDelete = (id: string) => {
    dispatch(
      deleteReferenceLine({
        plotId,
        referenceLineId: id,
      }),
    )
  }

  const onReferenceLineAdded = (
    value: number,
    orientation: "horizontal" | "vertical",
  ) => {
    dispatch(
      addReferenceLine({
        plotId: plotId,
        referenceLine: {
          id: uuidv4(),
          value,
          orientation,
        },
      }),
    )
  }

  if (!plotId || !chart || !plot || !seriesesData) {
    return <div ref={parentRef}></div>
  }
  return (
    <div className="relative w-full h-full" ref={parentRef}>
      {!!(seriesesData || []).length && !!width && !!height && (
        <svg
          ref={chartRef}
          width={"100%"}
          height={"100%"}
          // We listen to mouse move here so that it
          // covers outside of the chart area for the  ref line + - icons
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          preserveAspectRatio="xMidYMid meet"
        >
          <Group left={margin.left} top={margin.top}>
            {zoomSelection && (
              <BoxSelection
                zoomSelection={zoomSelection}
                showValues={isMeasureDistanceTask}
                xType={xType}
                xScale={xScale}
                yScale={yScale}
              />
            )}

            <line
              x1={0}
              y1={0}
              x2={innerWidth}
              y2={0}
              stroke="#CCC"
              strokeWidth={1}
            />
            <line
              x1={innerWidth}
              y1={0}
              x2={innerWidth}
              y2={innerHeight}
              stroke="#CCC"
              strokeWidth={1}
            />

            <Grid
              xScale={xScale}
              yScale={yScale}
              width={innerWidth}
              height={innerHeight}
              stroke="#e0e0e0"
              strokeOpacity={0.5}
              strokeDasharray="5,5"
            />

            {seriesesData && (
              <>
                <AxisBottom
                  scale={xScale}
                  top={innerHeight}
                  label={plot.label_x}
                  axisClassName="select-none pointer-events-none"
                  tickFormat={v => formatAxisX(v as number)}
                  stroke="#CCC"
                  numTicks={innerWidth / 120}
                  tickLabelProps={() => ({
                    fontSize: 11,
                    textAnchor: "middle",
                  })}
                />
                <AxisLeft
                  scale={yScale}
                  axisClassName="select-none axis-labels pointer-events-none"
                  label={plot.label_y || ""}
                  stroke="#CCC"
                  numTicks={innerHeight / 40}
                  tickLabelProps={() => ({
                    fontSize: 11,
                    textAnchor: "end",
                    dx: "-0.33em",
                    dy: "0.33em",
                  })}
                  labelProps={{
                    fontSize: 11,
                    textAnchor: "middle",
                  }}
                />
              </>
            )}

            <defs>
              <clipPath id="viewableArea">
                <rect x="0" y="0" width={innerWidth} height={innerHeight} />
              </clipPath>
            </defs>

            <g clipPath="url(#viewableArea)">
              {(seriesesData || []).map((dp, idx) => (
                <SeriesViewer
                  key={idx}
                  data={dp}
                  series={serieses[dp.series_id]}
                  xScale={xScale}
                  yScale={yScale}
                  seriesIdx={idx}
                  formatX={formatAxisX}
                  formatY={formatAxisY}
                  innerHeight={innerHeight}
                  innerWidth={innerWidth}
                  highlightPoints={highlightPoints}
                  totalSerieses={seriesesData.length}
                  hideBoxes={hideBoxes}
                />
              ))}

              <Annotations
                task={task}
                plotId={plotId}
                xScale={xScale}
                yScale={yScale}
                innerHeight={innerHeight}
                innerWidth={innerWidth}
              />
            </g>

            <ReferenceLines
              task={task}
              plotId={plotId}
              xScale={xScale}
              yScale={yScale}
              innerWidth={innerWidth}
              innerHeight={innerHeight}
              formatNumberY={formatAxisY}
              formatX={formatAxisX}
              handleReferenceLineDelete={handleReferenceLineDelete}
            />
            <Guider
              task={task}
              plotId={plotId}
              xScale={xScale}
              yScale={yScale}
              innerHeight={innerHeight}
              innerWidth={innerWidth}
              handleVerticalPlusClick={handleVerticalPlusClick}
              handleHorizontalPlusClick={handleHorizontalPlusClick}
              formatNumberY={formatAxisY}
              formatX={formatAxisXGuider}
            />
          </Group>
        </svg>
      )}
      {/* Legend */}
      {seriesesData && (
        <div style={{ 
          position: "absolute", 
          ...(plot?.legend_position === "top_left" ? { top: 30, left: 70 } :
             plot?.legend_position === "top_right" ? { top: 30, right: 30 } :
             plot?.legend_position === "bottom_left" ? { bottom: 70, left: 70 } :
             { bottom: 70, right: 30 }), // default to bottom_right
          fontSize: 11, 
          color: "#808080", 
          pointerEvents: "none" 
        }}>
          {seriesesData.map((series, idx) => (
            <Legend key={idx} series={serieses[series.series_id]} />
          ))}
        </div>
      )}
    </div>
  )
}

export default PlotViewer
