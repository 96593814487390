import { TASK_MEASURE, type DataPoint, type Task, type Series, type SeriesData } from "@/model"
import { 
  getPlotData, 
  selectPlot, 
  selectSerieses, 
  useAppSelector 
} from "@/store"
import { Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import React, { useMemo } from "react"
import LabeledText from "./LabeledText"
import { CreateMinusIcon } from "./util"
import { HighlightPoint } from "./HighlightPoint"

/**
 * Extended DataPoint interface to include series information
 */
interface IntersectionPoint extends DataPoint {
  seriesId: string;
  seriesColor?: string;
}

/**
 * Find the closest data point to the given x value within a certain threshold
 * @param dataPoints Array of data points
 * @param targetX The x value to find the closest point to
 * @param xScale Scale function to convert x values to pixels
 * @param threshold Maximum allowed distance in pixels
 */
const findClosestPoint = (
  dataPoints: DataPoint[],
  targetX: number,
  xScale: ScaleLinear<number, number>,
  threshold: number = 3
): DataPoint | null => {
  if (!dataPoints || dataPoints.length === 0) return null;
  
  // Binary search to find the closest point
  let left = 0;
  let right = dataPoints.length - 1;
  let closestIndex = -1;
  let minDistance = Infinity;
  
  // First pass: binary search to narrow down the region
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midX = dataPoints[mid].x;
    
    const distance = Math.abs(midX - targetX);
    
    if (distance < minDistance) {
      minDistance = distance;
      closestIndex = mid;
    }
    
    if (midX < targetX) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  
  // Now check if the closest point is within the threshold
  if (closestIndex >= 0) {
    const point = dataPoints[closestIndex];
    const pixelDistance = Math.abs(xScale(point.x) - xScale(targetX));
    
    if (pixelDistance <= threshold) {
      return point;
    }
  }
  
  // Also check adjacent points
  if (closestIndex > 0) {
    const leftPoint = dataPoints[closestIndex - 1];
    const leftPixelDistance = Math.abs(xScale(leftPoint.x) - xScale(targetX));
    if (leftPixelDistance <= threshold) {
      return leftPoint;
    }
  }
  
  if (closestIndex < dataPoints.length - 1) {
    const rightPoint = dataPoints[closestIndex + 1];
    const rightPixelDistance = Math.abs(xScale(rightPoint.x) - xScale(targetX));
    if (rightPixelDistance <= threshold) {
      return rightPoint;
    }
  }
  
  return null;
}

/**
 * Find data points close to the vertical reference line
 */
const findClosestPoints = (
  value: number,
  series: Series,
  seriesData: SeriesData[],
  xScale: ScaleLinear<number, number>
): IntersectionPoint[] => {
  // If the series is not visible, return empty array
  if (!series.visible) return []
  
  // Find the series data
  const data = seriesData.find(d => d.series_id === series.id)
  if (!data || !data.data_points || data.data_points.length === 0) return []
  
  const dataPoints = data.data_points
  
  // Find data points close to the reference line
  const closestPoint = findClosestPoint(dataPoints, value, xScale);
  if (!closestPoint) return [];
  
  // Return actual data point with series information
  return [{ 
    x: closestPoint.x, 
    y: closestPoint.y,
    seriesId: series.id,
    seriesColor: series.color
  }];
}

// Create a new component to render individual reference lines
// This helps avoid rendering issues with hooks in the main component
const ReferenceLine = React.memo(({
  line,
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  formatNumberY,
  formatX,
  handleReferenceLineDelete,
  task,
  intersectionPoints
}: {
  line: any;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  innerWidth: number;
  innerHeight: number;
  formatNumberY: (v: number) => string;
  formatX: (v: number) => string;
  handleReferenceLineDelete: (id: string) => () => void;
  task: Task;
  intersectionPoints: IntersectionPoint[];
}) => {
  return (
    <React.Fragment key={line.id}>
      <Line
        from={{
          x: line.orientation === "horizontal" ? 0 : xScale(line.value),
          y: line.orientation === "horizontal" ? yScale(line.value) : 0,
        }}
        to={{
          x:
            line.orientation === "horizontal"
              ? innerWidth
              : xScale(line.value),
          y:
            line.orientation === "horizontal"
              ? yScale(line.value)
              : innerHeight,
        }}
        stroke="gray"
        strokeWidth={1}
        strokeDasharray="5,5"
        style={{ pointerEvents: "none" }}
      />
      <LabeledText
        x={line.orientation === "horizontal" ? 0 : xScale(line.value)}
        y={
          line.orientation === "horizontal" ? yScale(line.value) : innerHeight
        }
        text={
          line.orientation === "horizontal"
            ? formatNumberY(line.value)
            : formatX(line.value)
        }
        orientation={line.orientation}
        variant="basic"
      />
      {task.task === TASK_MEASURE && (
        <CreateMinusIcon
          x={
            line.orientation === "horizontal"
              ? innerWidth + 13
              : xScale(line.value)
          }
          y={line.orientation === "horizontal" ? yScale(line.value) : -13}
          onClick={handleReferenceLineDelete(line.id)}
        />
      )}
      
      {/* Render highlight points for actual data points close to the vertical reference line */}
      {line.orientation === "vertical" && intersectionPoints.map((point, idx) => (
        <HighlightPoint
          key={`intersection-${line.id}-${idx}`}
          point={point}
          index={idx}
          xScale={xScale}
          yScale={yScale}
          formatY={formatNumberY}
          fillColor={point.seriesColor || "#444"}
          textColor="#000"
        />
      ))}
    </React.Fragment>
  );
});

export const ReferenceLines = ({
  plotId,
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  formatNumberY,
  formatX,
  handleReferenceLineDelete,
  task,
}: {
  plotId: string
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  innerWidth: number
  innerHeight: number
  formatNumberY: (v: number) => string
  formatX: (v: number) => string
  handleReferenceLineDelete: (id: string) => () => void
  task: Task
}) => {
  // Always call all hooks unconditionally at the top
  const plot = useAppSelector(selectPlot(plotId))
  const allSeries = useAppSelector(selectSerieses(plotId))
  
  // Get the actual series data points from the store
  const seriesData = getPlotData(plotId) || []
  
  // Safely access lines with a default empty array to avoid null issues
  const lines = plot?.reference_lines || []
  
  // Calculate all intersection points for all vertical reference lines
  // Always call useMemo even if we don't have lines
  const allIntersectionPoints = useMemo(() => {
    // Create a map of line id to intersection points
    const intersectionMap: Record<string, IntersectionPoint[]> = {};
    
    // For each line, calculate intersections if it's vertical
    lines.forEach(line => {
      if (line?.orientation === "vertical") {
        intersectionMap[line.id] = allSeries.flatMap(series => 
          findClosestPoints(line.value, series, seriesData, xScale)
        );
      } else {
        intersectionMap[line.id] = [];
      }
    });
    
    return intersectionMap;
  }, [lines, allSeries, seriesData, xScale]);

  // Render nothing if no plot or no lines, but do it after all hooks are called
  if (lines.length === 0 || !plot) {
    return null;
  }

  return (
    <>
      {lines.map(line => (
        <ReferenceLine
          key={line.id}
          line={line}
          xScale={xScale}
          yScale={yScale}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          formatNumberY={formatNumberY}
          formatX={formatX}
          handleReferenceLineDelete={handleReferenceLineDelete}
          task={task}
          intersectionPoints={allIntersectionPoints[line.id] || []}
        />
      ))}
    </>
  );
}
