import {
  FUNC_STEPS_FUNCTION,
  TASK_MEASURE,
  type ActiveLocalTaskDataSeriesFunction,
  type DataPoint,
  type Series,
  type SeriesData,
  type SeriesFunction
} from "@/model"
import { defaultColor } from "@/preference"
import {
  selectIsSelectingBox,
  selectLocalTask,
  selectPlotCurrentTask,
  setActiveLocalTask,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { isNoneColor } from "@/ui"
import { Group } from "@visx/group"
import { Circle } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import { useMemo } from "react"
import { StepsFunctionViewer } from "./functions/StepsFunctionViewer"
import { Legend } from "./Legend"
import { LinePath } from "./LinePath"
import { SeriesFunctionBox } from "./SeriesFunctionBox"
import { HighlightPoint } from "./HighlightPoint"

// Define a threshold for the maximum number of points to show circles
const MAX_POINTS_FOR_CIRCLES = 100

// First, create a Map for O(1) lookups of data points
const getSeriesHighlightPoints = (
  highlightPoints: DataPoint[] | undefined,
  dataPoints: DataPoint[],
) => {
  // Early return if no highlight points
  if (!highlightPoints?.length) return []

  // Create a Set of stringified data points for O(1) lookups
  const dataPointsSet = new Set(
    dataPoints.map(point => `${point.x},${point.y}`),
  )

  // Filter highlight points using the Set
  return highlightPoints.filter(point =>
    dataPointsSet.has(`${point.x},${point.y}`),
  )
}

export const addTransparency = (color: string, opacity: number): string => {
  if (isNoneColor(color)) {
    color = defaultColor
  }

  if (!color) {
    color = defaultColor
  }
  // Remove # if present
  color = color.replace("#", "")

  // Convert 3-digit hex to 6-digit hex
  if (color.length === 3) {
    color = color
      .split("")
      .map(char => char + char)
      .join("")
  }

  // Remove existing alpha if present
  if (color.length === 8) {
    color = color.slice(0, 6)
  }

  // Validate hex color
  if (!/^[0-9A-Fa-f]{6}$/.test(color)) {
    console.error("Invalid hex color, fallback to default")
    color = defaultColor
  }

  // Validate opacity
  if (opacity < 0 || opacity > 1) {
    console.error("Invalid opacity value, must be between 0 and 1")
    opacity = 1
  }

  // Convert opacity to hex
  const alpha = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, "0")

  return `#${color}${alpha}`
}

export const SeriesViewer = ({
  data,
  series,
  innerWidth,
  innerHeight,
  xScale,
  yScale,
  seriesIdx,
  formatX,
  formatY,
  highlightPoints,
  lineWidth = 1.5,
  totalSerieses,
  hideBoxes,
}: {
  data: SeriesData
  series: Series
  innerWidth: number
  innerHeight: number
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  formatX: (v: number) => string
  formatY: (v: number) => string
  seriesIdx: number
  highlightPoints?: DataPoint[]
  lineWidth?: number
  totalSerieses: number
  hideBoxes?: boolean
}) => {
  const dispatch = useAppDispatch()
  const showCircles = data.data_points.length <= MAX_POINTS_FOR_CIRCLES
  const isSelectingBox = useAppSelector(selectIsSelectingBox)
  const pointerEvents = isSelectingBox ? "none" : "auto"
  const currentLocalTask = useAppSelector(selectLocalTask)
  const task = useAppSelector(selectPlotCurrentTask(series?.plot_id))

  const seriesHighlightPoints = useMemo(
    () => getSeriesHighlightPoints(highlightPoints, data.data_points),
    [highlightPoints, data.data_points],
  )

  const setSelectedFunc = (
    id: string,
    type: string,
    style: Record<string, any>,
    x: number,
    y: number,
    width: number,
    height: number,
  ) => {
    dispatch(
      setActiveLocalTask({
        id,
        type,
        x,
        y: Math.max(y - 20, 10),
        subx: Math.min(x + width + 130, innerWidth - 200),
        suby: Math.max(y, 10),
        data: {
          plotId: series.plot_id,
          seriesId: series.id,
          seriesFunctionId: id,
        } as ActiveLocalTaskDataSeriesFunction,
      }),
    )
  }

  const functions = series?.functions || []
  const functionsMap = functions.reduce(
    (acc, func) => {
      acc[func.id] = func
      return acc
    },
    {} as Record<string, SeriesFunction>,
  )

  // Show series selector if there are more than one series and the user is on the measure task
  const showSeriesSelector = task?.task === TASK_MEASURE && totalSerieses > 1

  if (!series) {
    return null
  }

  return (
    <Group key={series.id}>
      {series.visible && (
        <>
          <LinePath
            data={data.data_points}
            xScale={xScale}
            yScale={yScale}
            strokeWidth={lineWidth}
            stroke={series.color || "#1f77b4"}
            plotId={series.plot_id}
            seriesId={series.id}
            showSelector={showSeriesSelector}
          />

          {(data.functions_data || []).map(funcData => {
            const func = functionsMap[funcData.id]

            if (func.style?.hidden) {
              return null
            }

            if (funcData.type === FUNC_STEPS_FUNCTION) {
              return (
                <StepsFunctionViewer
                  key={funcData.id}
                  func={func}
                  funcData={funcData}
                  innerWidth={innerWidth}
                  innerHeight={innerHeight}
                  xScale={xScale}
                  yScale={yScale}
                  pointerEvents={pointerEvents}
                  formatX={formatX}
                  formatY={formatY}
                />
              )
            }

            return null
          })}
          {showCircles &&
            data.data_points.map((point, index) => (
              <Circle
                key={`point-${index}`}
                cx={xScale(point.x)}
                cy={yScale(point.y)}
                r={3}
                fill={series.color || "#1f77b4"}
                pointerEvents={"none"}
              />
            ))}
          {seriesHighlightPoints.map((point, index) => (
            <HighlightPoint
              key={`highlight-point-${index}`}
              point={point}
              index={index}
              xScale={xScale}
              yScale={yScale}
              formatY={formatY}
            />
          ))}
        </>
      )}

      {/* Show boxes */}
      {functions.map((func, index) => {
        const x = xScale(func.data.x_min)
        const y = yScale(func.data.y_max)
        const width = Math.abs(
          xScale(func.data.x_max) - xScale(func.data.x_min),
        )
        const height = Math.abs(
          yScale(func.data.y_max) - yScale(func.data.y_min),
        )

        if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
          return null
        }

        const isActive = currentLocalTask?.id === func.id

        if (!series.visible || hideBoxes) {
          return null
        }

        return (
          <SeriesFunctionBox
            x={x}
            y={y}
            width={width}
            height={height}
            key={func.id}
            func={func}
            showBox={true}
            isActive={isActive}
            pointerEvents={pointerEvents}
            onSelected={() => {
              setSelectedFunc(
                func.id,
                func.type,
                func.style,
                x,
                y,
                width,
                height,
              )
            }}
          />
        )
      })}
    </Group>
  )
}
