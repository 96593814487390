import { attachChartDatasources } from "@/api"
import { Datasources } from "@/components/datasource/Datasources"
import { FileUpload } from "@/components/datasource/FileUpload"
import { updateDatasourceColumns } from "@/api/datasource"
import type { Chart, Column, Datasource, ColumnUpdate } from "@/model"
import {
  deleteCurrentDragDropItem,
  loadDatasources,
  selectDatasources,
  selectIsLoadingDatasources,
  setCurrentDragDropItem,
  updateDatasourceColumn,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import {
  DelayedSpinner,
  IconButton,
  Input,
  Modal,
  Select,
  EditableText,
  type SelectGroup,
  type SelectItem,
} from "@/ui"
import * as Popover from "@radix-ui/react-popover"
import { PlusIcon, Pencil } from "lucide-react"
import { useEffect, useState } from "react"
import { ColumnTypeIcon } from "./config/ColumnTypeIcon"

export const CompactDatasource = ({ chart }: { chart: Chart }) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [isSelectDatasourceModalOpen, setIsSelectDatasourceModalOpen] =
    useState(false)
  const [error, setError] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState("")

  const dispatch = useAppDispatch()
  const datasources = useAppSelector(selectDatasources)
  const isLoadingDatasources = useAppSelector(selectIsLoadingDatasources)

  let datasourceOptionsMap: Record<string, SelectItem | SelectGroup> = {}
  for (const key in datasources) {
    const ds = datasources[key]
    if (ds.parent_id) {
      let parentGroup: SelectGroup = (datasourceOptionsMap[
        ds.parent_id
      ] as SelectGroup) || {
        name: ds.parent_name,
        items: [],
      }

      parentGroup.items.push({ value: ds.id, name: ds.name })
      datasourceOptionsMap[ds.parent_id] = parentGroup
    } else {
      datasourceOptionsMap[ds.id] = {
        value: ds.id,
        name: ds.name,
      }
    }
  }
  const datasourceOptions = Object.values(datasourceOptionsMap)

  let datasourceIds = Object.keys(datasources)
  datasourceIds = datasourceIds.concat(
    datasourceIds.map(id => datasources[id].parent_id || "").filter(id => !!id),
  )

  const [selectedDatasource, setSelectedDatasource] =
    useState<Datasource | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useEffect(() => {
    const datasourceIds = Object.keys(datasources)
    if (!selectedDatasource && datasourceIds.length > 0) {
      setSelectedDatasource(datasources[datasourceIds[0]])
    }
  }, [datasources, selectedDatasource])

  const handleAddNewDatasource = () => {
    setIsFileUploadModalOpen(true)
    setIsPopoverOpen(false)
  }

  const handleSelectExistingDatasource = () => {
    setIsSelectDatasourceModalOpen(true)
    setIsPopoverOpen(false)
  }

  const handleSelectChange = (value: string) => {
    const newDatasource = datasources[value]
    if (newDatasource) {
      setSelectedDatasource(newDatasource)
    } else {
      console.error(`Datasource with id ${value} not found`)
    }
  }

  const handleSearchChange = (v: string) => {
    setSearchTerm(v)
  }

  const filterColumns = (columns: Column[]) => {
    if (!searchTerm) return columns
    return columns.filter(column =>
      column.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  }

  const onFilesUploaded = (datasource: Datasource) => {
    // attached the datasource to the chart
    ; (async () => {
      const id = datasource.id
      await attachChartDatasources(chart.id, [id])

      // reload chart datasources
      dispatch(loadDatasources(chart.id))
      setSelectedDatasource(datasource)
      setIsFileUploadModalOpen(false)
    })()
  }

  const onDatasourcesSelected = async (datasources: Datasource[]) => {
    const ids = datasources.map(ds => ds.id)
    await attachChartDatasources(chart.id, ids)
    dispatch(loadDatasources(chart.id))
    setSelectedDatasource(datasources[0])
    setIsSelectDatasourceModalOpen(false)
  }

  const handleDragStart = (
    e: React.DragEvent,
    data: { datasourceId: string; column: Column },
  ) => {
    dispatch(setCurrentDragDropItem(data))
  }

  const handleDragEnd = (e: React.DragEvent) => {
    e.preventDefault()
    dispatch(deleteCurrentDragDropItem())
  }

  const handleUpdateColumnFriendlyName = async (column: Column, newFriendlyName: string) => {
    if (!selectedDatasource) return

    if (column.friendly_name === newFriendlyName) return

    try {
      const columnUpdate: ColumnUpdate = {
        name: column.name,
        friendly_name: newFriendlyName
      }

      dispatch(updateDatasourceColumn({
        datasourceId: selectedDatasource.id,
        columnUpdate
      }));
    } catch (error) {
      console.error("Failed to update column friendly name:", error)
    }
  }

  if (isLoadingDatasources) {
    return (
      <div className="px-4 py-2">
        <div>
          <h2 className="text-md font-semibold ml-2 my-4">Datasources</h2>
          <div className="px-4 py-2 text-sm flex items-center justify-center">
            <DelayedSpinner size="sm" />
          </div>
        </div>
      </div>
    )
  }

  if (!datasources || Object.keys(datasources).length === 0) {
    return <div className="px-4 py-2">No datasources available</div>
  }

  return (
    <div className="px-4 py-2">
      <div>
        <div className="flex justify-between">
          <h2 className="text-md font-semibold ml-2 my-4">Datasources</h2>
          <IconButton
            icon={<PlusIcon className="w-3 h-3" />}
            text="Add Datasource"
            size="small"
            variant="ghost"
            onClick={() => handleAddNewDatasource()}
          />
        </div>

        {selectedDatasource && (
          <>
            <div>
              <Select
                name="datasource"
                value={selectedDatasource.id}
                items={datasourceOptions}
                onChange={handleSelectChange}
              />
            </div>
            <div className="mt-4 space-y-2 pb-3">
              <h2 className="text-sm font-semibold ml-2 my-2 text-gray-500">
                Fields
              </h2>
              <div>
                <Input
                  label="Search"
                  placeholder="column name"
                  name="search"
                  selectAllOnFocus={true}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  debounceTime={0}
                />
              </div>
              {filterColumns(selectedDatasource.columns || []).map(column => (
                <div
                  className="flex items-center text-xs border rounded hover:cursor-pointer hover:border-blue-400 hover:bg-blue-100"
                  key={column.name}
                  draggable
                  onDragStart={e =>
                    handleDragStart(e, {
                      column,
                      datasourceId: selectedDatasource.id,
                    })
                  }
                  onDragEnd={handleDragEnd}
                >
                  <div className="w-3 pl-3 h-3 mr-2">
                    <ColumnTypeIcon type={column.type} />
                  </div>
                  <div className="flex-grow">
                    <EditableText
                      value={column.friendly_name || column.name}
                      onSave={(newValue) => handleUpdateColumnFriendlyName(column, newValue)}
                      className="text-xs px-3 py-2"
                      tooltip={column.name === column.friendly_name ? undefined : column.name}
                      tooltipPosition="left"
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={isFileUploadModalOpen}
        size="large"
        onOpenChange={setIsFileUploadModalOpen}
        title="Add new datasource"
      >
        <div className="">
          <FileUpload
            onFilesUploaded={onFilesUploaded}
            onFilesUploadedError={setError}
            isPendingDatasource={false}
            multiple={true}
          />
          {error && (
            <p className="mt-4 text-red-500 text-center">Error: {error}</p>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isSelectDatasourceModalOpen}
        size="large"
        onOpenChange={setIsSelectDatasourceModalOpen}
        title="Select datasource"
      >
        <Datasources
          mode="form"
          onSelect={onDatasourcesSelected}
          preselectedIds={datasourceIds}
        />
      </Modal>
    </div>
  )
}
