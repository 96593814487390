import { Header, NavBar } from "@/components"
import { Login, ResetPassword, Signup } from "@/pages/auth"
import { Account } from "@/pages/auth/Account"
import { Charts, EditChart, NewChart, ViewPlot } from "@/pages/charts"
import { Templates } from "@/pages/templates"
import {
  getProfile,
  selectAuthenticated,
  store,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import Hotjar from "@hotjar/browser"
import { ToastProvider, ToastViewport } from "@radix-ui/react-toast"
import * as Sentry from "@sentry/react"
import React, { useEffect } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom"
import { ToastContainer } from "./components/Toast"
import "./index.css"
import {
  EditDatasource,
  ListDatasources,
  NewDatasource,
} from "./pages/datasources"
import Upload from "./pages/datasources/Upload"
const siteId = 5181171
const hotjarVersion = 6

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://af956d3595b469c899c17a99b233276b@o4508050440978432.ingest.us.sentry.io/4508050442485760",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.vizly\.au/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  Hotjar.init(siteId, hotjarVersion)
}

const PrivateRoute: React.FC = () => {
  const dispatch = useAppDispatch()
  const authenticated = useAppSelector(selectAuthenticated)
  const location = useLocation()

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  if (authenticated === undefined) {
    return <div>Loading...</div>
  }

  if (authenticated === false) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />
  }

  return <App />
}

const InternalRoute: React.FC = () => {
  return <Outlet />
}

export default PrivateRoute

const App: React.FC = () => {
  return (
    <div className="flex h-full">
      <NavBar />
      <div className="flex-1 flex flex-col ml-16">
        <Header />
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/charts" replace />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/signup",
    element: <Signup />,
  },
  {
    path: "/auth/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/internal",
    element: <InternalRoute />,
    children: [
      {
        path: "/internal/charts/:id/:plotId",
        element: <ViewPlot />,
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/charts",
        element: <Charts />,
        children: [],
      },
      {
        path: "/charts/new",
        element: <NewChart />,
      },
      {
        path: "/charts/:id",
        element: <EditChart />,
      },
      {
        path: "/datasources",
        element: <ListDatasources />,
      },
      {
        path: "/datasources/new",
        element: <NewDatasource />,
      },
      {
        path: "/datasources/upload",
        element: <Upload />,
      },
      {
        path: "/datasources/:id",
        element: <EditDatasource />,
      },
      {
        path: "/datasources/:id/review",
        element: <EditDatasource />,
      },
      {
        path: "/templates",
        element: <Templates />,
      },
    ],
  },
])

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastProvider swipeDirection="right">
          <RouterProvider router={router} />
          <ToastContainer />
          <ToastViewport className="fixed bottom-0 right-0 flex flex-col p-6 gap-2 w-96 max-w-[100vw] m-0 list-none z-50 outline-none" />
        </ToastProvider>
      </Provider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
