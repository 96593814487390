import { uploadFile } from '@/api';
import type { ChangeEvent } from 'react';
import type React from 'react';
import { useState, useRef, useEffect } from 'react'

type FileUploadProps = {
  onUploadComplete?: (url: string) => void
  onUploadError?: (error: Error) => void
  acceptedFileTypes?: string
  maxFileSizeMB?: number
  className?: string
}

type UploadStatus = 'idle' | 'uploading' | 'success' | 'error';

const Upload: React.FC<FileUploadProps> = ({
  onUploadComplete,
  onUploadError,
  acceptedFileTypes = "*",
  maxFileSizeMB = 2000,
  className = ""
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle')
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const fileInputRef = useRef<HTMLInputElement>(null)

  // Reset success message after 5 seconds
  useEffect(() => {
    if (uploadStatus === 'success') {
      const timeout = setTimeout(() => {
        setUploadStatus('idle');
        setSuccessMessage("");
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [uploadStatus]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null
    setErrorMessage("")
    setSuccessMessage("")
    setUploadStatus('idle')
    
    if (!file) {
      setSelectedFile(null)
      return
    }
    
    // Check file size
    const fileSizeInMB = file.size / (1024 * 1024)
    if (fileSizeInMB > maxFileSizeMB) {
      setErrorMessage(`File size exceeds the ${maxFileSizeMB}MB limit`)
      setSelectedFile(null)
      if (fileInputRef.current) fileInputRef.current.value = ""
      return
    }
    
    setSelectedFile(file)
  }
  
  const handleUpload = async () => {
    if (!selectedFile) {
      setErrorMessage("Please select a file first")
      return
    }
    
    try {
      setUploadStatus('uploading')
      
      // Call the uploadFile function directly
      const result = await uploadFile(selectedFile)
      
      setUploadStatus('success')
      setSuccessMessage(`${result.message || 'File uploaded successfully'} (${result.filename})`)
      setSelectedFile(null)
      if (fileInputRef.current) fileInputRef.current.value = ""
      
      // Notify parent component
      if (onUploadComplete) {
        onUploadComplete(result.filename)
      }
    } catch (error) {
      setUploadStatus('error')
      setErrorMessage(error instanceof Error ? error.message : "Upload failed")
      
      if (onUploadError && error instanceof Error) {
        onUploadError(error)
      }
    }
  }
  
  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }
  
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    
    const file = e.dataTransfer.files?.[0] || null
    if (!file) return
    
    // Check file size
    const fileSizeInMB = file.size / (1024 * 1024)
    if (fileSizeInMB > maxFileSizeMB) {
      setErrorMessage(`File size exceeds the ${maxFileSizeMB}MB limit`)
      return
    }
    
    setSelectedFile(file)
    setErrorMessage("")
    setSuccessMessage("")
    setUploadStatus('idle')
  }

  const resetUpload = () => {
    setSelectedFile(null)
    setUploadStatus('idle')
    setSuccessMessage("")
    setErrorMessage("")
  }

  return (
    <div className={`w-full p-16 ${className}`}>
      <div 
        className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                   ${uploadStatus === 'uploading' ? 'bg-gray-50 border-gray-300' : 
                     uploadStatus === 'success' ? 'bg-green-50 border-green-500' : 
                     'hover:bg-gray-50 border-gray-300 hover:border-blue-500'}
                   transition-colors duration-200`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={uploadStatus === 'success' ? resetUpload : handleBrowseClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept={acceptedFileTypes}
          className="hidden"
          disabled={uploadStatus === 'uploading'}
        />
        
        <div className="flex flex-col items-center justify-center">
          {uploadStatus === 'success' ? (
            // Success state
            <svg 
              className="w-12 h-12 mb-3 text-green-500" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M5 13l4 4L19 7"
              />
            </svg>
          ) : (
            // Upload icon
            <svg 
              className={`w-12 h-12 mb-3 ${uploadStatus === 'uploading' ? 'text-gray-400' : 'text-gray-400'}`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
          )}
          
          {uploadStatus === 'uploading' ? (
            <div className="text-sm font-medium text-gray-700">
              Uploading {selectedFile?.name}...
            </div>
          ) : uploadStatus === 'success' ? (
            <div className="text-sm font-medium text-green-700">
              Success! {successMessage}
              <p className="mt-2 text-xs text-green-600">Click to upload another file</p>
            </div>
          ) : (
            <>
              {selectedFile ? (
                <div className="mb-2">
                  <p className="text-sm font-medium text-gray-700">{selectedFile.name}</p>
                  <p className="text-xs text-gray-500">
                    {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                </div>
              ) : (
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
              )}
              <p className="text-xs text-gray-500">
                {acceptedFileTypes === "*" 
                  ? "Any file format" 
                  : `Supported formats: ${acceptedFileTypes}`}
                 {" · "} Up to {maxFileSizeMB}MB
              </p>
            </>
          )}
        </div>
      </div>
      
      {errorMessage && (
        <div className="mt-2 text-sm text-red-600">
          {errorMessage}
        </div>
      )}
      
      {selectedFile && uploadStatus === 'idle' && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleUpload();
          }}
          className="mt-3 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                    transition-colors duration-200 w-full"
        >
          Upload File
        </button>
      )}
    </div>
  )
}

export default Upload