import { Group } from "@visx/group"
import { Circle } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import { type DataPoint } from "@/model"

interface HighlightPointProps {
  /**
   * The data point to highlight
   */
  point: DataPoint
  /**
   * The index of the point, used for React keys
   */
  index: number
  /**
   * Scale function for the x axis
   */
  xScale: ScaleLinear<number, number>
  /**
   * Scale function for the y axis
   */
  yScale: ScaleLinear<number, number>
  /**
   * Function to format the y value for display
   */
  formatY: (value: number) => string
  /**
   * Color of the highlight circle
   * @default "#444"
   */
  fillColor?: string
  /**
   * Color of the tooltip text
   * @default "#000"
   */
  textColor?: string
  /**
   * Width of the tooltip box
   * @default 60
   */
  tooltipWidth?: number
  /**
   * Height of the tooltip box
   * @default 20
   */
  tooltipHeight?: number
  /**
   * Font size for the tooltip text
   * @default 11
   */
  fontSize?: number
  /**
   * Font family for the tooltip text
   * @default "Arial"
   */
  fontFamily?: string
  /**
   * The radius of the highlight circle
   * @default 3
   */
  circleRadius?: number
}

/**
 * A reusable component that renders a highlighted point with a tooltip
 * showing the y-value of the point.
 */
export const HighlightPoint = ({
  point,
  index,
  xScale,
  yScale,
  formatY,
  fillColor = "#444",
  textColor = "#000", 
  tooltipWidth = 60,
  tooltipHeight = 20,
  fontSize = 11,
  fontFamily = "Arial",
  circleRadius = 3
}: HighlightPointProps) => {
  return (
    <Group key={`highlight-group-${index}`}>
      <Circle
        key={`highlight-${index}`}
        cx={xScale(point.x)}
        cy={yScale(point.y)}
        r={circleRadius}
        fill={fillColor}
        pointerEvents={"none"}
      />
      <rect
        x={xScale(point.x) + 8}
        y={yScale(point.y) - 10}
        width={tooltipWidth}
        height={tooltipHeight}
        fill="rgba(255, 255, 255, 0.8)"
        stroke={fillColor}
        strokeWidth={1}
        rx={3}
        ry={3}
        pointerEvents={"none"}
      />
      <text
        x={xScale(point.x) + 12}
        y={yScale(point.y) + 5}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fill={textColor}
        pointerEvents={"none"}
      >
        {formatY(point.y)}
      </text>
    </Group>
  )
} 