import type { Plot } from "@/model"
import { chartLineColors } from "@/preference"
import {
  addSeries,
  selectSerieses,
  setActivePlotId,
  updatePlot,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { IconButton, Input, Select } from "@/ui"
import { ChevronDown, ChevronRight, PlusIcon } from "lucide-react"
import { useRef, useState } from "react"
import { SeriesConfig } from "./SeriesConfig"

export interface PlotConfigProps {
  id: string
  plotConfig: Plot
}

export const PlotConfig: React.FC<PlotConfigProps> = ({ id, plotConfig }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const serieses = useAppSelector(selectSerieses(plotConfig.id))
  const [isGeneralOpen, setIsGeneralOpen] = useState(false)
  const [isSeriesOpen, setIsSeriesOpen] = useState(true)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(setActivePlotId(plotConfig.id))
  }

  const onUpdatePlot = (data: Partial<Plot>) => {
    dispatch(updatePlot({ plotId: plotConfig.id, plot: data }))
  }

  const addNewSeriesConfig = async (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(
      addSeries({
        plotId: plotConfig.id,
      }),
    )
  }

  const toggleGeneral = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsGeneralOpen(!isGeneralOpen)
  }

  const toggleSeries = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsSeriesOpen(!isSeriesOpen)
  }

  return (
    <div className="mb-4 w-[280px]" onClick={handleClick}>
      <div ref={containerRef}>
        <h2 className="text-md font-semibold ml-2 mt-3">Plot Config</h2>
        <div className="p-2">
          <div className="space-y-2">
            <div className="flex justify-between items-center pt-1">
              <button
                onClick={toggleGeneral}
                className="flex items-center space-x-2 text-gray-500 hover:text-gray-700 transition-colors"
              >
                {isGeneralOpen ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
                <h3 className="font-bold text-sm">General</h3>
              </button>
            </div>

            {isGeneralOpen && (
              <div className="space-y-4">
                <div className="flex-1">
                  <Input
                    name="name"
                    value={plotConfig.name}
                    label="Name"
                    placeholder={plotConfig.name}
                    onChange={v => onUpdatePlot({ name: v })}
                  />
                </div>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Input
                      name="label_x"
                      value={plotConfig.label_x}
                      label="X"
                      placeholder="X Label"
                      onChange={v => onUpdatePlot({ label_x: v })}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      name="label_y"
                      value={plotConfig.label_y}
                      label="Y"
                      placeholder="Y Label"
                      onChange={v => onUpdatePlot({ label_y: v })}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <Select
                    name="legend_position"
                    value={plotConfig.legend_position || "bottom_right"}
                    label="Legend Position"
                    emptyText="Select Legend Position"
                    items={[
                      { name: "Top Left", value: "top_left" },
                      { name: "Top Right", value: "top_right" },
                      { name: "Bottom Left", value: "bottom_left" },
                      { name: "Bottom Right", value: "bottom_right" },
                    ]}
                    onChange={v => onUpdatePlot({ legend_position: v as "top_left" | "top_right" | "bottom_left" | "bottom_right" })}
                  />
                </div>
              </div>
            )}

            <div className="flex justify-between items-center pt-3">
              <button
                onClick={toggleSeries}
                className="flex items-center space-x-2 text-gray-500 hover:text-gray-700 transition-colors"
              >
                {isSeriesOpen ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
                <h3 className="font-bold text-sm">Series</h3>
              </button>
              <IconButton
                icon={<PlusIcon className="w-3 h-3" />}
                text="Add new series"
                onClick={addNewSeriesConfig}
                size="small"
                variant="ghost"
              />
            </div>

            {isSeriesOpen && (
              <div className="space-y-6">
                {serieses.map((config, index) => (
                  <SeriesConfig
                    colors={chartLineColors}
                    key={index}
                    seriesConfig={config}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlotConfig
