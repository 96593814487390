import { isUsedForX, isUsedForY } from "@/helpers"
import {
  FUNC_STEPS_FUNCTION,
  TASK_MEASURE,
  type Column,
  type Plot,
  type Series,
  type Task,
} from "@/model"
import {
  addSeries,
  deletePlot,
  doFitToY,
  doZoomOutRequest,
  movePlots,
  selectActivePlotId,
  selectChart,
  selectCurrentDragDropItem,
  selectLocalTask,
  selectPlotCurrentTask,
  selectPlotDataIsLoading,
  selectPlotXType,
  selectPlots,
  selectSerieses,
  setActivePlotId,
  setFunctionActiveSeriesId,
  setPlotCurrentTask,
  updateSeries,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { DelayedSpinner } from "@/ui"
import { ChevronDown, ChevronUp, MoveVertical, ZoomOut } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import { AddReferenceLine, SeriesFunctionConfig } from "./component"
import { LocalToolbar } from "./toolbar"
import { Toolbar } from "./toolbar/Toolbar"
import { useLocalToolbar } from "./toolbar/useLocalToolbar"
import { useHotKeys } from "./useHotKeys"
import PlotViewer from "./v2/PlotViewer"

export interface ContainerProps {
  plot: Plot
  focus?: boolean
}

export const Container = ({ plot }: ContainerProps) => {
  const task = useAppSelector(selectPlotCurrentTask(plot.id))
  const [canDropNewSeries, setCanDropNewSeries] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const chart = useAppSelector(selectChart)
  const dispatch = useAppDispatch()
  const plotId = plot.id
  const isActiveContainer = plotId === useAppSelector(selectActivePlotId)
  const xType = useAppSelector(selectPlotXType(plotId))
  const serieses = useAppSelector(selectSerieses(plotId))
  const currentDragDropItem = useAppSelector(selectCurrentDragDropItem) as {
    datasourceId: string
    column: Column
  }
  const localTask = useAppSelector(selectLocalTask)
  const allPlots = useAppSelector(selectPlots)

  // Add useEffect to handle scrolling when container becomes active
  useEffect(() => {
    if (isActiveContainer && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }, [isActiveContainer])

  const zoomOut = () => {
    dispatch(
      doZoomOutRequest({
        plotId: plotId,
      }),
    )
  }
  const isLoadingData = useAppSelector(selectPlotDataIsLoading(plotId))

  const fitToY = () => {
    dispatch(
      doFitToY({
        plotId: plotId,
      }),
    )
  }

  const onContainerDoubleClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(
      doZoomOutRequest({
        plotId: plotId,
      }),
    )
  }

  const onContainerSelected = () => {
    if (!isActiveContainer) {
      dispatch(setActivePlotId(plotId))
    }
  }

  const onTaskChanged = (t: Task) => {
    if (t.task === "delete_plot") {
      dispatch(deletePlot({ chartId: chart!.id, plotId: plotId }))
      return
    }

    // TODO: this is a hack to set default selected series
    if (
      task?.task !== t.task &&
      t.task === TASK_MEASURE &&
      !!(serieses || []).length
    ) {
      // set default selected series
      dispatch(setFunctionActiveSeriesId({ plotId, seriesId: serieses[0].id }))
    }

    dispatch(setPlotCurrentTask({ plotId, task: t }))
  }

  useHotKeys({
    isActiveContainer,
    onTaskChanged,
    zoomOut,
    fitToY,
  })

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setCanDropNewSeries(!!buildNewSeries())
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setCanDropNewSeries(true)
  }

  const buildNewSeries = (): Partial<Series> | undefined => {
    let newSeries: Partial<Series> | undefined = undefined
    const usedForX = isUsedForX(currentDragDropItem.column.type)
    const usedForY = isUsedForY(currentDragDropItem.column.type)

    // Drag drop to a new plot with no series yet
    // use the column as X or Y
    if (serieses.length === 0) {
      if (usedForX) {
        newSeries = {
          datasource_id: currentDragDropItem.datasourceId,
          x: currentDragDropItem.column.name,
          y: "",
        }
      } else {
        newSeries = {
          datasource_id: currentDragDropItem.datasourceId,
          x: "",
          y: currentDragDropItem.column.name,
        }
      }

      return newSeries
    }

    // if the existing series is empty, use the column as X or Y
    if (serieses.length === 1 && serieses[0].x === "" && serieses[0].y === "") {
      if (usedForX) {
        newSeries = {
          id: serieses[0].id,
          datasource_id: currentDragDropItem.datasourceId,
          x: currentDragDropItem.column.name,
          y: "",
        }
      } else {
        newSeries = {
          id: serieses[0].id,
          datasource_id: currentDragDropItem.datasourceId,
          x: "",
          y: currentDragDropItem.column.name,
        }
      }

      return newSeries
    }

    if (serieses.length === 1 && serieses[0].x !== "" && serieses[0].y === "") {
      if (usedForY) {
        newSeries = {
          id: serieses[0].id,
          datasource_id: currentDragDropItem.datasourceId,
          x: serieses[0].x,
          y: currentDragDropItem.column.name,
        }
      }

      return newSeries
    }

    const isExistingDatasourceId = serieses.find(
      series => series.datasource_id === currentDragDropItem.datasourceId,
    )?.datasource_id

    if (isExistingDatasourceId) {
      for (const series of serieses) {
        if (usedForY) {
          newSeries = {
            datasource_id: currentDragDropItem.datasourceId,
            x: series.x,
            y: currentDragDropItem.column.name,
          }
          break
        }
      }
    } else {
      if (usedForY) {
        newSeries = {
          datasource_id: currentDragDropItem.datasourceId,
          x: "",
          y: currentDragDropItem.column.name,
        }
      }
    }

    return newSeries
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const newSeries = buildNewSeries()
    if (newSeries) {
      if (newSeries.id) {
        dispatch(
          updateSeries({
            plotId: plotId,
            seriesId: newSeries.id,
            series: newSeries,
            refreshData: true,
          }),
        )
      } else {
        dispatch(
          addSeries({
            plotId: plotId,
            data: newSeries,
            refreshData: true,
          }),
        )
      }
    }
  }

  const { localToolbars } = useLocalToolbar(localTask)
  const activeLocalToolbar = localToolbars[localTask?.type || ""]

  useEffect(() => {
    if (!currentDragDropItem) {
      setCanDropNewSeries(true)
    }
  }, [currentDragDropItem])

  // Determine if this plot is at the top or bottom
  const plotIndex = allPlots.findIndex(p => p.id === plotId)
  const isFirstPlot = plotIndex === 0
  const isLastPlot = plotIndex === allPlots.length - 1

  const handleMovePlotUp = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!chart || isFirstPlot) return

    const currentIndex = plotIndex
    const newPlotIds: string[] = allPlots.map(p => p.id)
    // Swap with the plot above
    const temp = newPlotIds[currentIndex]
    newPlotIds[currentIndex] = newPlotIds[currentIndex - 1]
    newPlotIds[currentIndex - 1] = temp

    dispatch(movePlots({ chartId: chart.id, plotIds: newPlotIds }))
  }

  const handleMovePlotDown = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!chart || isLastPlot) return

    const currentIndex = plotIndex
    const newPlotIds: string[] = allPlots.map(p => p.id)
    // Swap with the plot below
    const temp = newPlotIds[currentIndex]
    newPlotIds[currentIndex] = newPlotIds[currentIndex + 1]
    newPlotIds[currentIndex + 1] = temp

    dispatch(movePlots({ chartId: chart.id, plotIds: newPlotIds }))
  }

  if (!plot || !chart) {
    return <div></div>
  }

  return (
    <div
      className="relative mt-12 pl-8 w-full"
      onDoubleClick={onContainerDoubleClicked}
      onClick={onContainerSelected}
      ref={containerRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="relative">
        <div
          className={`w-full relative border border-1 bg-white rounded-lg shadow-lg overflow-hidden mb-6 ${
            isActiveContainer
              ? "border-blue-500 border-2"
              : "border-gray-300 border-2"
          } ${canDropNewSeries ? "" : "border-red-500"}`}
        >
          {isActiveContainer && localTask && (
            <LocalToolbar
              localToolbar={localTask}
              top={localTask.y}
              left={localTask.x}
              containerRef={containerRef}
              children={activeLocalToolbar}
            />
          )}
          {isLoadingData && (
            <div className="absolute inset-0 flex items-center justify-center z-50">
              <DelayedSpinner size="md" />
            </div>
          )}
          <div className="p-4">
            <div className="w-full h-[350px] relative select-none">
              <PlotViewer task={task} plotId={plotId} />
            </div>
          </div>
        </div>
        <div className="absolute -top-6 right-1 origin-top-left flex items-center space-x-2">
          <span className="text-xs text-gray-500">{plot?.name}</span>
        </div>
        
        {/* Move plot up/down buttons to top right of the plot */}
        <div className="absolute top-0 -right-5 flex flex-col z-10">
          <button
            onClick={handleMovePlotUp}
            className={`p-0.5 transition-colors duration-200 bg-white/70 rounded-full ${
              isFirstPlot
                ? "text-gray-300 cursor-not-allowed"
                : "text-gray-500 hover:text-blue-500"
            }`}
            title={isFirstPlot ? "Already at the top" : "Move plot up"}
            disabled={isFirstPlot}
          >
            <ChevronUp size={14} />
          </button>
          <button
            onClick={handleMovePlotDown}
            className={`p-0.5 transition-colors duration-200 bg-white/70 rounded-full ${
              isLastPlot
                ? "text-gray-300 cursor-not-allowed"
                : "text-gray-500 hover:text-blue-500"
            }`}
            title={isLastPlot ? "Already at the bottom" : "Move plot down"}
            disabled={isLastPlot}
          >
            <ChevronDown size={14} />
          </button>
        </div>
      </div>

      {plot?.zoom?.x_min !== undefined && (
        <div className="absolute top-0 right-0 origin-top-left flex">
          <button
            onClick={fitToY}
            className="p-1 text-gray-500 hover:text-blue-500 transition-colors duration-200"
          >
            <MoveVertical size={14} />
          </button>
          <button
            onClick={zoomOut}
            className="p-2 text-gray-500 hover:text-blue-500 transition-colors duration-200"
          >
            <ZoomOut size={16} />
          </button>
        </div>
      )}

      {task && (
        <Toolbar
          enabled={isActiveContainer}
          activeTask={task}
          onTaskChanged={onTaskChanged}
          chartId={chart.id}
          plotId={plotId}
        />
      )}

      <SeriesFunctionConfig
        plotId={plotId}
        initialTop={localTask?.suby || 100}
        initialLeft={Math.max(100, localTask?.subx || 0)}
        initialCollapsed={localTask?.subCollapsed}
        enabled={localTask?.type === FUNC_STEPS_FUNCTION && isActiveContainer}
        containerRef={containerRef}
      />

      <AddReferenceLine
        plotId={plotId}
        containerRef={containerRef}
        verticalType={xType}
        enabled={
          task?.task === TASK_MEASURE && task.subtask === "reference_line"
        }
      />
    </div>
  )
}

export default Container
