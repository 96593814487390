import { Pencil, Check } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import * as Tooltip from '@radix-ui/react-tooltip'

// Define the tooltip position type
type TooltipPosition = 'top' | 'right' | 'bottom' | 'left'

export const EditableText = ({
  value,
  onSave,
  className,
  tooltip,
  tooltipPosition = 'top',
}: {
  value: string
  onSave: (text: string) => void
  className?: string
  tooltip?: string
  tooltipPosition?: TooltipPosition
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const spanRef = useRef<HTMLSpanElement>(null)
  const originalValueRef = useRef<string>(value)

  useEffect(() => {
    if (isEditing && spanRef.current) {
      // Store the original value when editing starts
      originalValueRef.current = value
      
      spanRef.current.focus()
      const selection = window.getSelection()
      const range = document.createRange()

      if (spanRef.current.childNodes.length > 0) {
        const textNode = spanRef.current.childNodes[0]
        range.setStart(textNode, 0)
        range.setEnd(textNode, textNode.textContent?.length || 0)
      } else {
        range.setStart(spanRef.current, 0)
        range.setEnd(spanRef.current, 0)
      }

      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [isEditing, value])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    if (spanRef.current) {
      const newValue = spanRef.current.textContent || ""
      onSave(newValue)
      setIsEditing(false)
    }
  }

  const handleCancel = () => {
    if (spanRef.current) {
      // Restore the original value
      spanRef.current.textContent = originalValueRef.current
      setIsEditing(false)
    }
  }

  const content = (
    <div
      className={`relative flex items-center w-full ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={!isEditing ? handleEdit : undefined}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.preventDefault()
          handleSave()
        } else if (e.key === "Escape") {
          e.preventDefault()
          handleCancel()
        }
      }}
      onBlur={handleSave}
    >
      <span
        ref={spanRef}
        contentEditable={isEditing}
        suppressContentEditableWarning
        className={`outline-none flex-grow ${isEditing ? "cursor-text" : ""}`}
      >
        {value}
      </span>
      {(isHovered && !isEditing) && (
        <button
          onClick={handleEdit}
          className="ml-auto inline-flex items-center hover:text-blue-500 rounded"
        >
          <Pencil size={13} />
        </button>
      )}
      {isEditing && (
        <button
          onClick={handleSave}
          className="ml-auto inline-flex items-center hover:text-green-500 rounded"
        >
          <Check size={14} />
        </button>
      )}
    </div>
  )

  // If tooltip is provided, wrap the content with Tooltip components
  if (tooltip) {
    return (
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            {content}
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
              sideOffset={5}
              side={tooltipPosition}
            >
              {tooltip}
              <Tooltip.Arrow className="fill-gray-800" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    )
  }

  // Return content without tooltip if no tooltip is provided
  return content
}
